import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "apply_panel" }
const _hoisted_2 = { class: "interactive_two" }

import CommonTable from '@/components/CommonTable.vue';
import CommonPagination from '@/components/CommonPagination.vue';
import { ref, watch, getCurrentInstance } from 'vue'
import { CONST } from '@/types/const'
import AddApplyPanel from '@/components/AddApplyPanel.vue';


  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      CommonTable,
      CommonPagination,
      AddApplyPanel
    }
  },
  __name: 'ApplyPanel',
  props: {
    panel: {}
  },
  setup(__props: any) {



  let props = __props;
  const { appContext: { config : { globalProperties } } } = getCurrentInstance() as any;

  let tableData = ref([])
  let pageNum = ref(1)
  let pageSize = ref(15)
  let totalNum = ref(100)
  let show = ref(true)
  let list = ref([
    { title: 'standardTimeliness', type: 1 },
    { title: 'standardProject', type: 2 },
    { title: 'applicationStandard', type: 3 },
  ])

  watch(() => props.panel, (n, o) => {
    if(n && n != o) {
      show.value = false;
      showPage()
    }
  }, {
    immediate: true
  })

  function showPage() {
    globalProperties.$API.FileList({
      type: list.value.filter(item => {return item.title == props.panel })[0].type,
      pageNum: pageNum.value,
      pageSize: pageSize.value
    }).then((res: any) => {
      if(res.data.code == 200) {
        show.value = true;
        totalNum.value = res.data.data.total;
        tableData.value = res.data.data.records
      }
    })
  }

  function submitEvent(data: any) {
    let type = list.value.filter(item => {return item.title == props.panel })[0].type;
    let fileData = [] as any;
    let keys =  Object.keys(data);
    let link = [] as any;
    keys.forEach((item: any) => { if(typeof(data[item]) == 'object') fileData.push(data[item])})
    fileData.forEach((item: any, index: number) => {
      const formData1 = new FormData();
      formData1.append('file', item);
      formData1.append('type', 'OTHER');
      globalProperties.$API.UploadFile(formData1).then((res: any) => {
        link.push({ 
          id: res.data.id, 
          type: type == 1 || type == 3 ? (index == 0 ? 1 : 2 ) : 
          (index == 0 ? 3 : index == 1 ? 4 : index == 2 ? 5 : 6)
        })
      })
    })
    let time = setInterval(() => {
      if(link.length == fileData.length) {
        clearInterval(time);
        submitForm(link, data)
      }
    }, 100)
  }

  function submitForm(FileList: any, data: any) {
    globalProperties.$API.SubmitFileForm({
      attachmentList: FileList,
      type: list.value.filter((item : any)=> {return item.title == props.panel })[0].type,
      phone: data.phone,
      email: data.email,
    }).then((res: any) => {
      if(res.data.code == 200) {
        pageNum.value = 1;
        showPage();
      }
    })
  }

  function downClick(data: any) {
      window.location = globalProperties.$API.DownloadFile({
        fileName: data.fileName,
        path: data.url,
      })
    }



return (_ctx: any,_cache: any) => {
  const _component_icon_file = _resolveComponent("icon-file")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(show))
      ? (_openBlock(), _createBlock(AddApplyPanel, {
          key: 0,
          panel: _ctx.$props.panel,
          onSubmit: submitEvent
        }, null, 8, ["panel"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h3", null, "申请记录", -1)),
      _createVNode(CommonTable, {
        columns: (_unref(CONST) as any).applyTable,
        tableData: _unref(tableData)
      }, {
        options: _withCtx(({ record }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.attachmentList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "file",
              key: item.id
            }, [
              _createElementVNode("span", null, [
                _createVNode(_component_icon_file),
                _createTextVNode(_toDisplayString(item.fileName), 1)
              ]),
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: ($event: any) => (downClick(item))
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("下载")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["columns", "tableData"]),
      _createVNode(CommonPagination, {
        pageNum: _unref(pageNum),
        pageSize: _unref(pageSize),
        totalNum: _unref(totalNum),
        onPageChange: _cache[0] || (_cache[0] = (page) => { _isRef(pageNum) //@ts-ignore
 ? pageNum.value = page : pageNum = page; showPage() }),
        onSizeChange: _cache[1] || (_cache[1] = (size) => { _isRef(pageNum) //@ts-ignore
 ? pageNum.value = 1 : pageNum = 1; _isRef(pageSize) //@ts-ignore
 ? pageSize.value = size : pageSize = size; showPage() })
      }, {
        left: _withCtx(() => [
          _createTextVNode(" 共" + _toDisplayString(_unref(totalNum)) + "条，每页" + _toDisplayString(_unref(pageSize)) + "条", 1)
        ]),
        _: 1
      }, 8, ["pageNum", "pageSize", "totalNum"])
    ])
  ]))
}
}

})