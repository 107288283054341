import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "userCenter" }
const _hoisted_2 = { class: "right_panel" }
const _hoisted_3 = { class: "common_title2" }

import TypeList from '@/components/TypeList.vue';
import UserPanel from './components/UserPanel.vue';
import InteractiveListPanel from './components/InteractiveListPanel.vue';
import CollectPanel from './components/CollectPanel.vue';
import Waiting from './components/Waiting.vue';
import ApplyPanel from './components/ApplyPanel.vue';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CONST } from '@/types/const'


export default /*@__PURE__*/_defineComponent({
  ...{ 
  components: {
    TypeList,
    UserPanel,
    InteractiveListPanel,
    CollectPanel,
    Waiting,
    ApplyPanel
  }
},
  __name: 'Index',
  setup(__props) {



const route = useRoute()
const router = useRouter();
let title = ref((CONST.commonType as any)[route.meta.nav as any][0].label)
let panel = ref((CONST.commonType as any)[route.meta.nav as any][0].value)
let key = ref((CONST.commonType as any)[route.meta.nav as any][0].value)

function tabEvent(item: any) {
  if(item.value == 'loginOut') return loginOut()
  title.value = item.label
  panel.value = item.panel || item.value
  key.value = item.value
}

function loginOut() {
  router.push('/')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TypeList, {
      list: (_unref(CONST).commonType as any)[_ctx.$route.meta.nav as any],
      onTabEvent: tabEvent
    }, null, 8, ["list"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_unref(title)), 1),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(panel)), { panel: _unref(key) }, null, 8, ["panel"]))
    ])
  ]))
}
}

})