import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "interactive_list_panel" }
const _hoisted_2 = { class: "interactive_two" }

import CommonTable from '@/components/CommonTable.vue';
import CommonPagination from '@/components/CommonPagination.vue';
import { onMounted, ref, getCurrentInstance } from 'vue'
import { CONST } from '@/types/const'
import AddInteractivePanel from './AddInteractivePanel.vue';

  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      CommonTable,
      CommonPagination,
      AddInteractivePanel
    }
  },
  __name: 'InteractiveListPanel',
  setup(__props) {


  const { appContext: { config : { globalProperties } } } = getCurrentInstance() as any;

  let tableData = ref([])
  let pageNum = ref(1)
  let pageSize = ref(15)
  let totalNum = ref(100)

  onMounted(() => {
    showPage()
  })

  function showPage() {
    globalProperties.$API.CommunicationList({
      pageNum: pageNum.value,
      pageSize: pageSize.value
    }).then((res: any) => {
      if(res.data.code == 200) {
        res.data.data.records.forEach((item: any) => {
          item.answer = item.answer || '-'
        })
        tableData.value = res.data.data.records;
        totalNum.value = res.data.data.total;
      }
    })
  }

  function submitEvent(data: any) {
    globalProperties.$API.SubmitCommunication({
      title: data.title,
      content: data.content,
    }).then((res: any) => {
      if(res.data.code == 200) {
        pageNum.value = 1;
        showPage();
      }
    })
  }



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AddInteractivePanel, { onSubmit: submitEvent }),
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", null, "提交记录", -1)),
      _createVNode(CommonTable, {
        columns: (_unref(CONST) as any).submitRecordsTable,
        tableData: _unref(tableData)
      }, null, 8, ["columns", "tableData"]),
      _createVNode(CommonPagination, {
        pageNum: _unref(pageNum),
        pageSize: _unref(pageSize),
        totalNum: _unref(totalNum),
        onPageChange: _cache[0] || (_cache[0] = (page) => { _isRef(pageNum) //@ts-ignore
 ? pageNum.value = page : pageNum = page; showPage() }),
        onSizeChange: _cache[1] || (_cache[1] = (size) => { _isRef(pageNum) //@ts-ignore
 ? pageNum.value = 1 : pageNum = 1; _isRef(pageSize) //@ts-ignore
 ? pageSize.value = size : pageSize = size; showPage() })
      }, {
        left: _withCtx(() => [
          _createTextVNode(" 共" + _toDisplayString(_unref(totalNum)) + "条，每页" + _toDisplayString(_unref(pageSize)) + "条", 1)
        ]),
        _: 1
      }, 8, ["pageNum", "pageSize", "totalNum"])
    ])
  ]))
}
}

})