import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add_interactive_form" }

import { ref} from 'vue'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'AddInteractivePanel',
  emits: ['submit'],
  setup(__props, { emit: __emit }) {

  let emit = __emit
  let form = ref({
    title: '',
    content: ''
  })
  let formRef = ref(null)

  function  handleSubmit({values, errors}: { [key: string]: any }) {
    if(errors) {
      return;
    }else {
      emit('submit', form.value)
    }
  }

  function resetClick() {
    (formRef.value as any).resetFields();
    (formRef.value as any).clearValidate();
  }


return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h3", null, "提交反馈", -1)),
    _createVNode(_component_a_form, {
      class: "form_panel",
      model: _unref(form),
      ref_key: "formRef",
      ref: formRef,
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "title",
          label: "标题",
          rules: [{required:true,message:'请输入标题'}],
          "validate-trigger": ['change','input']
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: _unref(form).title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(form).title) = $event)),
              placeholder: "请输入标题",
              "show-word-limit": "",
              "max-length": 50,
              "allow-clear": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "content",
          label: "内容",
          rules: [{required:true,message:'请输入内容'}],
          "validate-trigger": ['change','input']
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_textarea, {
              modelValue: _unref(form).content,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(form).content) = $event)),
              placeholder: "请输入内容",
              "show-word-limit": "",
              "max-length": 300,
              "allow-clear": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_divider),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("提交")
              ])),
              _: 1
            }),
            _createVNode(_component_a_button, { onClick: resetClick }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("重置")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})