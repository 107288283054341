import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "collect_panel" }

import CommonTable from '@/components/CommonTable.vue'
  import CommonPagination from '@/components/CommonPagination.vue'
  import { CONST } from '@/types/const'
  import { onMounted, ref, getCurrentInstance } from 'vue'

  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      CommonPagination,
      CommonTable
    }
  },
  __name: 'CollectPanel',
  setup(__props) {

  

  let totalNum = ref(100)
  let pageNum = ref(1)
  let pageSize = ref(10)
  let checked = ref(false);
  let checkedList = ref([]);
  let clearC = ref(false);
  let indeterminate = ref(false);
  let tableData = ref([])
  const { appContext: { config : { globalProperties } } } = getCurrentInstance() as any;

  onMounted(() => {
    showPage()
  })

  // function changeSelect() {
  //   indeterminate.value = false;
  // }

  function checkChange(data: any) {
    if(data.type == 2) {
      checked.value = true
      indeterminate.value = false
    }else if(data.type == 1) {
      checked.value = false
      indeterminate.value = true
    }else {
      checked.value = false
      indeterminate.value = false
    }
    checkedList.value = data.data
  }

  function showPage() {
    globalProperties.$API.MyCollectStandard().then((res: any) => {
      if(res.data.code == 200) {
        if(res.data.data.length > 0) {
          res.data.data.forEach((item: any) => {
            let data = (CONST as any).standardState.filter((line: any) => { 
              return line.value ==  item.standardState 
            })
            item.standard_number = item.standardNumber
            item.statusName = data[0].label;
            item.color = data[0].color;
            item.chineseName = item.chineseName || item.englishName;
            item.releaseDate = item.releaseDate ? item.releaseDate.split(' ')[0] : ''
            item.implementDate = item.implementDate ?  item.implementDate.split(' ')[0] : ''
          })
        }
        
        tableData.value = res.data.data
      }
    })
  }

  function optionClick(list: object | null) {
    clearC.value = false;
    let ids = list ? [list] : checkedList.value;
    globalProperties.$API.DeleteCollectStandard({
      listStandardNumber: ids
    }).then((res: any) => {
      if(res.data.code == 200) {
        indeterminate.value = false;
        checked.value = false;
        clearC.value = true;
        showPage();
        globalProperties.$message.success('操作成功');
      }else {
        globalProperties.$message.error(res.data.msg || '操作失败');
      }
    })
  }


return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (optionClick(null)))
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode("一键取消收藏")
      ])),
      _: 1
    }),
    _createVNode(CommonTable, {
      checked: _unref(checked),
      columns: (_unref(CONST) as any).collectTable,
      tableData: _unref(tableData),
      select: true,
      clearC: _unref(clearC),
      indeterminate: _unref(indeterminate),
      onCheckChange: checkChange
    }, {
      options: _withCtx(({ record }) => [
        _createVNode(_component_a_button, {
          type: "text",
          onClick: ($event: any) => (optionClick(record.standardNumber))
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("取消收藏")
          ])),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["checked", "columns", "tableData", "clearC", "indeterminate"])
  ]))
}
}

})