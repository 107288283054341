<template>
  <div class="waiting">
    <img src="../../../assets/img/building.png" />
    <p>板块建设中，敬请期待</p>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
  .waiting {
    width: 100%;
    text-align: center;
    padding: 50px 0;

    & > img {
      width: 200px;
      height: 178px;
      margin-bottom: 30px;
    }

    & > p {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #4e5969;
      line-height: 20px;
    }
  }
</style>