import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, isRef as _isRef, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "center_user_panel" }

import { ref, getCurrentInstance, watch, onMounted } from 'vue'
  import validate  from '@/types/validate'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
  
export default /*@__PURE__*/_defineComponent({
  __name: 'UserPanel',
  setup(__props) {

  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);

  let userInfoForm = ref([
  { title: '用户名', key: 'account', place: '请输入用户名', rules: [{required:true,message:'请输入用户名'}], length: 10},
  { title: '电话', key: 'phone', place: '请输入电话', rules: [
    { required:true, message:'请输入电话' },
    { validator: validate.validPhoneOne, trigger: 'change' }
  ], length: 11, dis: true },
   { title: '邮箱', key: 'email', place: '请输入邮箱', rules: [
   { required:true, message:'请输入邮箱' },
    { validator: validate.validateEmail, trigger: 'change' }
  ], length: 50 },
  { title: '单位名称', key: 'unitName', place: '请输入单位名称', rules: [{required:true,message:'请输入单位名称'}], length: 50},
  { title: '联系人', key: 'contact', place: '请输入联系人', rules: [{required:true,message:'请输入联系人'}], length: 10},
  { title: '地址', key: 'address', place: '请输入地址', rules: [{required:true,message:'请输入地址'}], length: 50},
  ])

  let form = ref({})
  let edit = ref(false)
  let change = ref(false)
  let formRef = ref(null)
  const router = useRouter();
  const store = useStore();

  onMounted(() => {
    showUserInfo()
  })

  function showUserInfo() {
    globalProperties.$API.GetUserInfo().then((res: any) => {
      if(res.data.code == 200) {
        if(!change.value) form.value = res.data.data;
        store.commit('userInfo_type', res.data.data)
      }else  if(res.data.code == 511) {
        globalProperties.$message.error(res.data.msg || '您还未登录，请先登录！')
        router.push(`/login`)
      }
    })
  }

  function  handleSubmit({values, errors}: { [key: string]: any }) {
    if(errors) {
      return;
    }else {
      saveClick()
    }
  }

  function resetClick() {
    edit.value = false;
    (formRef.value as any).clearValidate();
  }

  function saveClick() {
    change.value = true;
    showUserInfo();
    globalProperties.$API.EditUserInfo({ ...form.value  }).then((res: any) => {
      if(res.data.code == 200) {
        change.value = false;
        globalProperties.$message.success('修改成功');
        resetClick();
      }else {
        globalProperties.$message.error(res.data.msg)
      }
    })
  }

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      class: "form_panel",
      model: _unref(form),
      ref_key: "formRef",
      ref: formRef,
      "label-align": "left",
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userInfoForm), (item) => {
          return (_openBlock(), _createBlock(_component_a_form_item, {
            key: item.key,
            field: item.key,
            label: item.title,
            rules: item.rules,
            "hide-asterisk": true,
            "validate-trigger": ['change','input']
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                modelValue: (_unref(form) as any)[item.key],
                "onUpdate:modelValue": ($event: any) => (((_unref(form) as any)[item.key]) = $event),
                placeholder: item.place,
                "show-word-limit": "",
                "max-length": item.length,
                disabled: item.dis || !_unref(edit),
                "allow-clear": ""
              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "max-length", "disabled"])
            ]),
            _: 2
          }, 1032, ["field", "label", "rules"]))
        }), 128)),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            (!_unref(edit))
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  type: "primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(edit) //@ts-ignore
 ? edit.value = true : edit = true))
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("编辑")
                  ])),
                  _: 1
                }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    "html-type": "submit"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("保存")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_button, { onClick: resetClick }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("取消")
                    ])),
                    _: 1
                  })
                ], 64))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})